import Label from "../Label/Label";
import "./Input.scss";

const Input = ({
  label,
  name,
  value = "",
  placeholder,
  showSeparator = true,
  onChange = () => {},
  labelWidth = 100,
  inputWidth = 100,
  inputpaddingleft=0,
  fontFamily = "Times, serif",
  labelFontFamily = "TImes, serif",
  variant = "default",
}) => {
  return (
    <div className="input">
      {label && (
        <Label
          variant={variant}
          showSeparator={showSeparator}
          name={name}
          labelWidth={labelWidth}
          label={label}
          fontFamily={labelFontFamily}
        />
      )}
      <input
        className="input__input"
        name={name}
        placeholder={placeholder}
        style={{
          width: `${inputWidth}px`,
          paddingLeft:`${inputpaddingleft}px`,
          fontSize: "11.1px",
          fontFamily,
        }}
        type="text"
        defaultValue={value}
        // value={value}
        // onChange={onChange}
      />
    </div>
  );
};

export default Input;
