import ReportFooter from "../ReportFooter/ReportFooter";
import Input from "../Input/Input";
import Label from "../Label/Label";
import Logo from "./../../assets/images/Logo.png";
import { ROOT_URI } from "../../constants/constants";
import DynamicTextArea from "../DynamicTextArea/DynamicTextArea";
import "./firstpage.css";

const FirstPage = ({
  report,
  handleChange,
  handleArrayChange,
  totalPages,
  userId,
}) => {
  let pvc12 = report.pvc[12];

  // Check if pvc12 contains an "@" and is not null/undefined
  if (pvc12 && pvc12.includes("@")) {
    // Separate the value and date part at the "@"
    let [pvc12Value, pvc12Date] = pvc12.split("@");

    // Safely extract time (HH:mm:ss) and date (YYYY-MM-DD) if pvc12Date exists
    let formattedTime = pvc12Date
      ? pvc12Date.match(/(\d{2}:\d{2}:\d{2})/)?.[0]
      : "";
    let formattedDate = pvc12Date
      ? pvc12Date
          .match(/(\d{4}-\d{2}-\d{2})/)?.[0]
          .split("-")
          .slice(1)
          .reverse()
          .join("-")
      : "";

    // Combine the value with the formatted time and date
    pvc12 = `${pvc12Value.trim()} ${formattedTime} ${formattedDate}`;

    console.log("pvc12:", pvc12); // Example: "3 beats(60 bpm) 03:20:12 10-02"
  } else {
    console.error("Invalid format for pvc12 or missing '@'.");
  }

  let pvc13 = report.pvc[13];

  // Check if pvc13 contains an "@" and is not null/undefined
  if (pvc13 && pvc13.includes("@")) {
    // Separate the value and date part at the "@"
    let [pvc13Value, pvc13Date] = pvc13.split("@");

    // Safely extract time (HH:mm:ss) and date (YYYY-MM-DD) if pvc13Date exists
    let formattedTimepvc13 = pvc13Date
      ? pvc13Date.match(/(\d{2}:\d{2}:\d{2})/)?.[0]
      : "";
    let formattedDatepvc13 = pvc13Date
      ? pvc13Date
          .match(/(\d{4}-\d{2}-\d{2})/)?.[0]
          .split("-")
          .slice(1)
          .reverse()
          .join("-")
      : "";

    // Combine the value with the formatted time and date
    pvc13 = `${pvc13Value.trim()} ${formattedTimepvc13} ${formattedDatepvc13}`;

    console.log("pvc13:", pvc13); // Example: "2 beats(70 bpm) 04:30:45 11-03"
  } else {
    console.error("Invalid format for pvc13 or missing '@'.");
  }

  // Output the afib value for debugging
  console.log("report!!!!!!!!!!!!!!!", report.afib);
  function formatAfibValue(afibData) {
    if (!afibData) {
      return ""; // Handle case when afibData is undefined or null
    }

    // Ensure the string contains " On " to avoid split errors
    const [beatsPart, datePart] = afibData.includes(" On ")
      ? afibData.split(" On ")
      : [afibData, ""];

    // Split the date to get day and month
    const date = datePart
      ? datePart
          .split("-")
          .slice(2)
          .concat(datePart.split("-").slice(1, 2))
          .join("-")
      : ""; // Get dd-mm

    // Format the output
    return `${beatsPart.trim()} ${date}`; // Return "9 beats(61 bpm) @02:13:42 10-02"
  }

  function formatAfibFastestValue(afibData) {
    if (!afibData) {
      return ""; // Handle case when afibData is undefined or null
    }

    // Ensure the string contains " on " to avoid split errors
    const [beatsPart, datePart] = afibData.includes(" on ")
      ? afibData.split(" on ")
      : [afibData, ""];

    // Split the date to get day and month
    const date = datePart
      ? datePart
          .split("-")
          .slice(2)
          .concat(datePart.split("-").slice(1, 2))
          .join("-")
      : ""; // Get dd-mm

    // Format the output
    return `${beatsPart.trim()} ${date}`; // Return "63 bpm (3 beats) @02:13:39 10-02"
  }
  const capitalizeEachWord = (str) => {
    return str.replace(/\b\w/g, (char) => char.toUpperCase());
  };
  return (
    <div className="report__page">
      {/* <img src={Template} alt="" className="report__template" /> */}
      <div className="report__header">
        <img src={Logo} alt="Logo" className="report__header-logo" />

        <div className="report__title">
          <p>Extented Holter</p>
          <p>Study Report</p>
        </div>

        <p className="report__underline-header report__analysis-time">
          Analysis Time
        </p>
      </div>
      <div
        className="report__form-container"
        style={{ left: "20px", top: "67.34px" }}
      >
        {/* FIRST ROW */}
        <div className="report__form-row">
          <Input
            label="Patient Name"
            name="name"
            labelWidth={73}
            inputWidth={161}
            value={report.name}
          />
          <Input
            label="Gender"
            labelWidth={41}
            inputWidth={62}
            value={report.gender}
            name="gender"
          />
          <Input
            label="Age"
            labelWidth={14}
            inputWidth={45}
            value={report.age}
            placeholder="Age"
            name="age"
          />

          {/* <Label label="Day" name="day" labelWidth={50} showSeparator={false} />  */}

          <Label label="Duration" variant="small" labelWidth={33} />
          <Input
            value={report.duration}
            name="duration"
            placeholder="30"
            inputWidth={120}
          />
        </div>

        {/* SECOND ROW */}
        <div className="report__form-row second-row">
          {report.ip_op && report.ip_op !== "NA" ? (
            <Input
              label="MRD No."
              labelWidth={69}
              inputWidth={56}
              value={report.ip_op}
              name="ip_op"
            />
          ) : (
            <Input
              label="Patient ID"
              labelWidth={69}
              inputWidth={56}
              value={report.device_id}
              name="device_id"
            />
          )}

          <Input
            label="Indication"
            labelWidth={45}
            inputWidth={90}
            value={report.indication}
            name="indication"
          />
          <Input
            label="Analysed Beats"
            labelWidth={80}
            inputWidth={56}
            value={report.total_beatscount}
            name="beats"
          />

          <Input
            label="From"
            labelWidth={27}
            inputWidth={125}
            value={report.from}
            placeholder="From"
            name="from"
            className="from-container"
          />
        </div>
        {/* THIRD ROW */}
        <div className="report__form-row">
          <Input
            label="Organization"
            labelWidth={73}
            inputWidth={123}
            value={capitalizeEachWord(report.hospital)}
            name="Organization"
          />

          <Input
            label="Referred By"
            labelWidth={64}
            inputWidth={146}
            value={report.doctorName}
            name="doctorName"
          />

          <Input
            label="To"
            labelWidth={31}
            inputWidth={110}
            value={report.to}
            name="to"
            placeholder="To"
          />

          {/* <Input
            label="BMI"
            labelWidth={30}
            inputWidth={50}
            value={parseInt(report.weight/((report.height/100)*(report.height/100)))}
            name="bmi"
          /> */}

          {/* <Input
            label="CHA2DS2-VASc Score"
            labelWidth={120}
            inputWidth={100}
            value={report.CHA2Vasc_Score}
            name="CHA2Vasc"
            placeholder="0"
          /> */}
        </div>
        {/* FOURTH ROW */}
      </div>

      <div className="report__mid-form-container report__mid-form-container--1">
        <div className="report__mid-form-header">
          <p>ATRIAL ECTOPICS</p>
        </div>

        <div className="report__mid-form">
          <Input
            label="Total"
            labelFontFamily="Bookman Old Style"
            labelWidth={70}
            inputWidth={140}
            value={report.pac[0]}
          />
          <Input
            label="Isolated"
            labelFontFamily="Bookman Old Style"
            labelWidth={70}
            inputWidth={100}
            value={report.pac[4]}
          />
          <Input
            label="Couplet"
            labelFontFamily="Bookman Old Style"
            labelWidth={70}
            inputWidth={100}
            value={report.pac[5]}
          />
          <Input
            label="Longest"
            labelFontFamily="Bookman Old Style"
            labelWidth={70}
            inputWidth={178}
            value={`${report.pac[12]}${report.pac12Date}`}
          />
          {/* <Input
            showSeparator={false}
            label=" "
            labelFontFamily="Bookman Old Style"
            labelWidth={70}
            inputWidth={135}
            value={report.pac12Date}
            name="pac12Date"
          /> */}
          <Input
            label="Fastest"
            labelFontFamily="Bookman Old Style"
            labelWidth={70}
            inputWidth={178}
            value={`${report.pac[13]} ${report.pac13Date}`}
          />
          {/* <Input
            showSeparator={false}
            label=" "
            labelFontFamily="Bookman Old Style"
            labelWidth={70}
            inputWidth={135}
            value={report.pac13Date}
            name="pac13Date"
          /> */}
          <Input
            label="Trigeminy"
            labelFontFamily="Bookman Old Style"
            labelWidth={70}
            inputWidth={126}
            value={report.pac[10]}
          />
          <Input
            label="Bigeminy"
            labelFontFamily="Bookman Old Style"
            labelWidth={70}
            inputWidth={126}
            value={report.pac[11]}
          />
          {/* <Input
            label="Afib / Flutter"
            labelFontFamily="Bookman Old Style"
            labelWidth={70}
            inputWidth={126}
            value={report.afib[0]}
          /> */}
        </div>
      </div>

      <div className="report__mid-form-container report__mid-form-container--2">
        <div className="report__mid-form-header">
          <p>VENTRICULAR ECTOPICS</p>
        </div>

        <div className="report__mid-form">
          <Input
            label="Total"
            labelFontFamily="Bookman Old Style"
            labelWidth={72}
            inputWidth={126}
            value={report.pvc[0]}
          />
          <Input
            label="Isolated"
            labelFontFamily="Bookman Old Style"
            labelWidth={72}
            inputWidth={126}
            value={report.pvc[4]}
          />
          <Input
            label="Couplet"
            labelFontFamily="Bookman Old Style"
            labelWidth={72}
            inputWidth={126}
            value={report.pvc[5]}
          />
          <Input
            label="Longest"
            labelFontFamily="Bookman Old Style"
            labelWidth={78}
            inputWidth={181}
            value={pvc12}
          />

          <Input
            label="Fastest"
            labelFontFamily="Bookman Old Style"
            labelWidth={78}
            inputWidth={181}
            value={pvc13}
          />

          <Input
            label="Trigeminy"
            labelFontFamily="Bookman Old Style"
            labelWidth={72}
            inputWidth={140}
            value={report.pvc[10]}
          />

          <Input
            label="Bigeminy"
            labelFontFamily="Bookman Old Style"
            labelWidth={72}
            inputWidth={140}
            value={report.pvc[11]}
          />
          <Input
            label="Episodes"
            labelFontFamily="Bookman Old Style"
            labelWidth={72}
            inputWidth={140}
            value={report.vt[5]}
          />
        </div>
      </div>

      <div className="report__mid-form-container report__mid-form-container--3">
        <div className="report__mid-form-header">
          <p>PAUSES</p>
        </div>

        <div className="report__mid-form">
          <Input
            label="Pauses in excess of 2 Sec"
            labelFontFamily="Bookman Old Style"
            labelWidth={170}
            inputWidth={40}
            value={report.pausesInExcess}
            name="pausesInExcess"
          />
          <Input
            label="Longest RR"
            labelFontFamily="Bookman Old Style"
            labelWidth={105}
            inputWidth={100}
            value={`${report.maxPause} Sec`}
            name="maxPause"
          />
        </div>
      </div>

      {/* <div className="report__mid-form-container report__mid-form-container--4">
        <div className="report__mid-form-header">
          <p>
            HEART RATE
            <span
              style={{
                fontSize: "9.5px",
                fontFamily: "Bookman Old Style",
                paddingLeft: "2px",
              }}
            >
              [4 RR Interval]
            </span>
          </p>
        </div>

        <div className="report__mid-form">
          <Input
            label="Maximum HR"
            labelFontFamily="Bookman Old Style"
            labelWidth={105}
            inputWidth={100}
            value={report.max_hr}
            name="max_hr"
          />
          <Input
            label="Minimum HR"
            labelFontFamily="Bookman Old Style"
            labelWidth={105}
            inputWidth={100}
            value={report.min_hr}
            name="min_hr"
          />
          <Input
            label="Average HR"
            labelFontFamily="Bookman Old Style"
            labelWidth={105}
            inputWidth={100}
            value={report.avg_heartrate}
            name="avg_heartrate"
          />
        </div>
      </div> */}
      <div className="report__mid-form-container report__mid-form-container--5">
        <div className="report__mid-form-header">
          <p>Afib / Flutter</p>
        </div>
        <div className="report__mid-form">
          {report.afib[0] === "0|0%" ? (
            <p className="afib-container">No AFIB Noted</p>
          ) : (
            <Input
              label="Burden"
              labelFontFamily="Bookman Old Style"
              labelWidth={65}
              inputWidth={140}
              value={report.afib[0]}
            />
          )}
          {report.afib[4] != 0 && (
            <Input
              label="Longest"
              labelFontFamily="Bookman Old Style"
              labelWidth={70}
              inputWidth={193}
              value={formatAfibValue(report.afib[6])}
            />
          )}
          {report.afib[4] != 0 && (
            <Input
              label="Fastest"
              labelFontFamily="Bookman Old Style"
              labelWidth={70}
              inputWidth={193}
              value={formatAfibFastestValue(report.afib[7])}
            />
          )}
        </div>
      </div>

      <div className="report__images">
        {report.images.map((image) => (
          <div className="report__imageContainer" key={image.key}>
            <input className="report__image-title" defaultValue={image.name} />
            <img
              className="report__image"
              src={`${ROOT_URI}/assets/dist/user_records/${userId}/${image.key}/cropped_image/max_${image.key}.png`}
              alt=""
            />
          </div>
        ))}
      </div>

      <img
        className="report__pie-chart"
        src={`${ROOT_URI}/assets/dist/user_records/${userId}/main_pie_chart.png`}
        alt="PIE CHART"
      />

      <div className="report__findingsninterpretation">
        <div className="report__findings">
          <p className="header">FINDINGS</p>
          <div style={{ flex: 1 }}>
            <DynamicTextArea value={report.findings} />
          </div>
        </div>
        {/* <div className="report__interpretation"> */}
        {/* <p className="header">INTERPRETATION</p> */}
        {/* <textarea
            type="paragraph"
            defaultValue={report.Interpretation}
            name="findings"
            className="content"></textarea> */}
        {/* </div> */}
      </div>

      <ReportFooter page="1" totalPage={totalPages} />
    </div>
  );
};

export default FirstPage;
